<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Master from "@/apis/Master";
/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "ACTIVITY LOGS",
      items: [
        {
          text: "DASHBOARD",
          href: "/"
        },
        {
          text: "ACTIVITY LOGS",
          active: true
        }
      ],
      logData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [10, 25, 50, 100, 125, 150, 200, 225, 250],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      selected:"true",
      fields: [
        { key: "slNo", sortable: false, label: "SL NO",  thStyle: { color: "black", "font-size":"16px"} },
        { key: "fullName", sortable: false, label: "FIRST NAME",  thStyle: { color: "black", "font-size":"16px"}  },
        { key: "subject", sortable: false, label: "SUBJECT",  thStyle: { color: "black", "font-size":"16px"}  },
        { key: "message", sortable: false, label: "MESSAGE",  thStyle: { color: "black", "font-size":"16px"}  },
        { key: "description", sortable: false, label: "REQUEST DATA",  thStyle: { color: "black", "font-size":"16px"}  },
        { key: "url", sortable: false, label: "URL",  thStyle: { color: "black", "font-size":"16px"}  },
        { key: "method", sortable: false, label: "METHOD",  thStyle: { color: "black", "font-size":"16px"}  },
        { key: "ip", sortable: false, label: "IP",  thStyle: { color: "black", "font-size":"16px"}  },
        { key: "agent", sortable: false, label: "AGENT", thStyle: { color: "black", "font-size":"16px"}   },
        { key: "createdAt", sortable: false, label: "DATE TIME", thStyle: { color: "black", "font-size":"16px"}   },

      ],
      tag:"ACCESSED",
      // logMessage:[
      //   {key:"dashboard", message:"DASHBOARD"},
      //   {key:"login", message:"LOGIN"},
      //   {key:"vendors", message:"BRANDS"},
      //   {key:"register", message:"REGISTER"},
      // ]

    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.logData.length;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.logData.length;

    Master.activityLogs().then((res) => {
      this.logData = res.data.data;
    })
  },

  methods: {
    /**
     * Edit Country Modal
     */




    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<template>
  <Layout>

    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" style="text-transform: uppercase !important;">
            <b-tabs nav-class="nav-tabs-custom">
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <div class="table-responsive">
                <b-table
                    class="table-centered"
                    :items="logData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                    striped
                    sort-icon-left
                >

                  <template v-slot:cell(fullName)="row">
                    <p class="badge font-size-12"> {{row.item.firstName}} {{row.item.lastName}}</p>
                  </template>
                  <template v-slot:cell(subject)="row">
                    <p class="badge font-size-12">
                      {{row.item.subject}}
                    </p>
                  </template>
                  <template v-slot:cell(description)="row">
                    <p class="badge font-size-12 text-breaker">
                      {{row.item.description}}
                    </p>
                  </template>
                  <template v-slot:cell(message)="row">
                    <p class="badge font-size-12">
<!--                    <p class="badge font-size-12" v-for="(log,index) in logMessage" :key="index">-->
<!--                      <span v-if="log.key == row.item.subject">-->
                    {{row.item.subject}} {{tag}}
<!--                  </span>-->
                    </p>
                  </template>
                  <template v-slot:cell(method)="row">
                    <p class="badge font-size-12" >
                      {{row.item.method}}
                    </p>
                  </template>
                  <template v-slot:cell(ip)="row">
                    <p class="badge font-size-12">
                      {{row.item.ip}}
                    </p>
                  </template>
                  <template v-slot:cell(agent)="row">
                    <p class="badge font-size-12 text-breaker">
                      {{row.item.agent}}
                    </p>
                  </template>
                    <template v-slot:cell(createdAt)="row">
                    <p class="badge font-size-12 text-breaker">
                      {{  new Date(row.item.createdAt).toLocaleString()  }}
                    </p>
                  </template>
                  <template v-slot:cell(url)="row">
                    <a target="_blank" :href="row.item.url"  class="badge font-size-12">{{row.item.url}}</a>
                  </template>
                  <template v-slot:cell(fullName)="row">
                    <p class="badge font-size-12">{{row.item.firstName}} {{row.item.lastName}}</p>
                  </template>

                  <template v-slot:cell(slNo)="row">
                    <p class="badge font-size-12">{{row.index+1}}</p>
                  </template>

                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
              <!--              </b-tab>-->
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
<style>
  .text-breaker{
    width: 100%;
    /*word-break: break-word;*/
    display: block;
    text-align: left;
    white-space: pre-wrap;
    line-height: 1.25em;
  }
</style>